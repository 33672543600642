// Add click event on encrypted links
document
    .querySelectorAll("[data-l][data-atc]")
    .forEach((link) => {
        link.addEventListener("click", (event) => {
            event.stopImmediatePropagation();
            window.open(getLink(link), getTarget(link));
        }, false);
    });

function getLink(link) {
    return decryptLink(link.getAttribute("data-l"));
}
function getTarget(link) {
    let target = "_self";
    const attribute = link.getAttribute("data-t");
    if (attribute) {
        target = decryptLink(attribute);
    }

    return target;
}

/**
*  Return decrypted link
*/
function decryptLink(encrypted) {
    // Remove the start and end characters of the string
    encrypted = encrypted.substr(1, encrypted.length - 2);

    // Change "=pt=" into "."
    encrypted = encrypted.replace(/=pt=/g, ".");

    const encryptedArray = encrypted.split(""),
        decryptedArray = [];

    // Re-Assigns the letter for the ciphered new one
    encryptedArray.forEach((encrypted) => {
        let character = encrypted;

        if (isLetter(character)) {
            character = String.fromCharCode(cipher(character));
        }

        decryptedArray.push(character);
    });

    return decryptedArray.join("");
}

function isLetter(char) {
    return /[a-z]/i.test(char);
}

function cipher(letter) {
    const charCode = letter.charCodeAt(0), // ASCII
        exceedsM = charCode < 97 ? charCode > 77 : charCode > 109;

    return exceedsM ? charCode - 13 : charCode + 13;
}